import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
//import leaf from "../../Assets/Projects/leaf.png";
//import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
//import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Work </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I have worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              
              title="Text Based Adventure Game"
              description="Embark on a thrilling journey in a captivating text-based adventure game created with Node.js. Explore an immersive and interactive world where your decisions shape the storyline. With each step, your choices will determine the outcome of your quest, offering multiple paths and endings for endless replayability."
              ghLink="https://github.com/wheattthins/text-based-adventure-game/tree/main"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}              
              title="Password Strength Checker"
              description="Simply enter your password, and our intelligent algorithm will assess its strength, taking into account factors like length, complexity, and uniqueness. The real-time feedback and detailed analysis provided by the checker ensure that you craft passwords that stand up to even the most sophisticated cyber threats."
              ghLink="https://github.com/wheattthins/Password-Strength-Checker/tree/main/Password-Strength-Checker"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Pong Arcade Game"
              description="Players control two paddles, one on each side of the screen, using simple keyboard inputs to maneuver and hit the ball. The objective is to keep the ball in play, preventing it from crossing the boundaries of either side. As the game progresses, the speed and intensity increase, challenging players' reflexes and strategic thinking."
              ghLink="https://github.com/wheattthins/Pong-Arcade-Game/tree/main"              
            />
          </Col>

         

          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;